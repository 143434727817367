<template>
  <div>
    <v-form
      v-if="assists.tool.isNotEmpty(settings.elements)"
      ref="form"
      v-bind="settings.originalProperty"
    >
      <ValidationObserver ref="observer" v-slot="{ validate, reset }">
        <v-row v-bind="settings.originalProperty">
          <template v-for="(field, index) in settings.elements">
            <v-col cols="12" :key="index">
              <ValidationProvider
                :name="field.elementLabel"
                :rules="field.foregroundProperty.validateRules"
                v-slot="{ errors }"
                style="width: 100%"
              >
                <v-radio-group
                  v-if="field.dataControl === 'VRadioGroup'"
                  v-bind="field.controlProperty"
                  v-model="current[field.elementKey]"
                  :label="field.elementLabel"
                  :error-messages="errors[0]"
                  hide-details="auto"
                >
                  <v-radio
                    v-for="(option, index) in field.dataDomainValues"
                    :key="index"
                    color="var(--theme_secondary)"
                    :label="option.text"
                    :value="option.value"
                  >
                    <template #label>
                      <span>{{ field.elementLabel }}</span>
                      <span
                        v-if="field.foregroundProperty.mandatory === 'true'"
                        class="red-dot">*</span>
                    </template>
                  </v-radio>
                </v-radio-group>

                <template v-else-if="field.dataControl === 'VDatePicker'">
                  <wap-date-picker
                    v-model="current[field.elementKey]"
                    :label="field.elementLabel"
                    :control-properties="field.controlProperty"
                    :allowed="field.foregroundProperty.allowedDates"
                    :format-value="current[field.elementKey]"
                    :necessarily="Boolean(field.foregroundProperty.mandatory)"
                    :error-message="errors[0]"
                  ></wap-date-picker>
                </template>

                <template v-else-if="field.dataControl === 'VSelect'">
                  <wap-select
                    v-model="current[field.elementKey]"
                    :label="field.elementLabel"
                    :properties="field.controlProperty"
                    :necessarily="Boolean(field.foregroundProperty.mandatory)"
                    :error-message="errors[0]"
                    :items="assists.tool.getWapArrayByEnum(field.dataDomainValues)"
                  ></wap-select>
                </template>

                <template v-else-if="field.dataControl === 'VTextField'">
                  <v-text-field
                    v-if="assists.tool.isNotEmpty(field.foregroundProperty.format)"
                    v-bind="field.controlProperty"
                    :value="field.foregroundProperty.format === 'ssn'? assists.tool.lockFormat('ssn', current[field.elementKey]) : current[field.elementKey]"
                    :label="field.elementLabel"
                    :error-messages="errors[0]"
                    hide-details="auto"
                    @blur="blurEvent(field.elementKey)"
                  >
                    <template #label>
                      <span>{{ field.elementLabel }}</span>
                      <span
                        v-if="field.foregroundProperty.mandatory === 'true'"
                        class="red-dot"
                      >*</span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-else-if="assists.tool.isNotEmpty(field.foregroundProperty) && assists.tool.isNotEmpty(field.foregroundProperty.mask)"
                    v-bind="field.controlProperty"
                    v-model="current[field.elementKey]"
                    :label="field.elementLabel"
                    :error-messages="errors[0]"
                    hide-details="auto"
                    v-mask="field.foregroundProperty.mask"
                    @blur="blurEvent(field.elementKey)"
                  >
                    <template #label>
                      <span>{{ field.elementLabel }}</span>
                      <span
                        v-if="field.foregroundProperty.mandatory === 'true'"
                        class="red-dot"
                      >*</span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-else
                    v-bind="field.controlProperty"
                    v-model="current[field.elementKey]"
                    :label="field.elementLabel"
                    :error-messages="errors[0]"
                    hide-details="auto"
                    @blur="blurEvent(field.elementKey)"
                  >
                    <template #label>
                      <span>{{ field.elementLabel }}</span>
                      <span v-if="field.foregroundProperty.mandatory === 'true'" class="red-dot">
                        *
                      </span>
                    </template>
                  </v-text-field>
                </template>

                <template v-else-if="field.dataControl === 'VText'">
                  <span
                    v-if="assists.tool.isNotEmpty(field.controlProperty.text)"
                    v-html="formatQuestion(field.controlProperty.text)"
                    :class="field.foregroundProperty.labelRowClass"
                  ></span>
                  <span v-else>
                    <v-row dense>
                      <v-col
                        v-if="field.foregroundProperty.hideLabel === 'true'"
                        :class="field.foregroundProperty.colClass"
                      >
                        {{assists.tool.sectionRender(field, current, currentLockedId)}}
                      </v-col>
                      <template
                        v-else-if="field.foregroundProperty.labelPosition === 'front'"
                      >
                        <v-col
                          :cols="field.foregroundProperty.labelCols"
                          :class="field.foregroundProperty.labelRowClass"
                        >
                          {{ field.elementLabel }}
                        </v-col>
                        <v-col :class="field.foregroundProperty.colClass">
                          {{assists.tool.sectionRender(field, current, currentLockedId) }}
                        </v-col>
                      </template>
                    </v-row>
                  </span>
                </template>
                <template v-else-if="field.dataControl === 'VDivider'">
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </template>
                <template v-else-if="field.dataControl === 'VSpacer'">
                  <v-spacer></v-spacer>
                </template>
                <component
                  v-else
                  v-bind="field.controlProperty"
                  :is="field.dataControl"
                  v-model="current[field.elementKey]"
                  :label="field.elementLabel"
                  :items="field.dataDomainValues"
                  :error-messages="errors[0]"
                  hide-details="auto"
                >
                  <template #label>
                    <span>{{ field.elementLabel }}</span>
                    <span
                      v-if="field.foregroundProperty.mandatory === 'true'"
                      class="red-dot">
                      *
                    </span>
                  </template>
                </component>
              </ValidationProvider>
            </v-col>
          </template>
        </v-row>
      </ValidationObserver>
    </v-form>
    <!-- <SSkeleton v-else :data-template-code="dataTemplateCode"></SSkeleton> -->
  </div>
</template>
<script>
import { PhoenixConfigApi, InfrastructureApi } from '@/api'
import { VTextField, VSelect, VSwitch } from 'vuetify/lib'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Tool } from '@/assets/js/util'
import { ajax } from '@/assets/js/util/ajax'
import { _ } from 'vue-underscore'
import WapDatePicker from '@/components/base/WapDatePicker'
import WapSelect from '@/components/base/WapSelect'

// import SSkeleton from '@/components/widget/SSkeleton'

export default {
  name: 'SForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    VTextField,
    VSelect,
    VSwitch,
    WapDatePicker,
    WapSelect
    // SSkeleton
  },
  props: {
    value: {
      type: [Object, Array]
    },
    dataTemplateCode: {
      type: String,
      required: true
    },
    actionParameters: {
      type: Object
    },
    saveParameters: {
      type: Object
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    formSettingFromSection: {
      type: Object
    }
  },
  data () {
    return {
      menu: false,
      assists: {
        tool: new Tool()
      },
      current: {},
      original: {},
      formsSettings: {},
      currentLockedId: null
    }
  },
  watch: {
    value (val) {
      if (this.assists.tool.isNotEmpty(val)) {
        this.current = { ...val }
      }
    },
    current: {
      handler (newVal) {
        this.$emit('blurAction', newVal)
      },
      deep: true
    },
    formSettingFromSection: {
      handler (val) {
        if (this.assists.tool.isNotEmpty(val)) {
          this.formsSettings = val
        }
      }
    }
  },
  computed: {
    settings () {
      const initForm = JSON.parse(JSON.stringify(this.formsSettings))
      let formSettings = initForm[this.dataTemplateCode]
      if (this.assists.tool.isEmpty(formSettings)) {
        formSettings = {
          originalProperty: {},
          elements: []
        }
      }
      if (this.assists.tool.isNotEmpty(formSettings.associates)) {
        const elements = formSettings.elements
        elements.map((item, index) => {
          formSettings.associates.forEach(val => {
            if (
              this.assists.tool.isNotEmpty(val) &&
              this.judgmentDisplayAssociate(val) &&
              item.templateElementId === val.sortId
            ) {
              const childrenForm = initForm[val.code]
              elements.splice(index + 1, 0, ...childrenForm.elements)
            }
          })
        })
      }
      formSettings.elements.map(item => {
        if (this.assists.tool.isNotEmpty(item.controlProperty)) {
          this.transferStringValue(item.controlProperty)
        }
      })
      if (this.assists.tool.isNotEmpty(formSettings.originalProperty)) {
        this.transferStringValue(formSettings.originalProperty)
      }
      return formSettings
    }
  },
  mounted () {
    if (this.assists.tool.isEmpty(this.formSettingFromSection)) {
      this.getFormByCode()
    } else {
      this.formsSettings = this.formSettingFromSection
      this.load()
    }
  },
  methods: {
    getFormByCode () {
      PhoenixConfigApi.getFormByCode(this.dataTemplateCode, res => {
        this.formsSettings = res
        // 将read url传给父组件
        const formSettings = res[this.dataTemplateCode]
        // 如是携带数据，则不请求接口
        if (this.assists.tool.isNotEmpty(this.value)) {
          this.current = this.value
          return
        }
        if (this.assists.tool.isNotEmpty(formSettings?.actionProperty)) {
          this.load()
        }
      })
    },
    load () {
      let url = this.formsSettings[this.dataTemplateCode].actionProperty.read.url
      if (this.assists.tool.isNotEmpty(this.actionParameters)) {
        url = url.replace(
          /\{([^}]+)\}/g,
          (_, value) => this.actionParameters[value]
        )
      }
      ajax.get(url, {}, res => {
        this.current = { ...res }
        this.original = { ...res }

        this.$emit('syncData', { ...res })
      })
    },
    saveForm () {
      const formSettings = this.formsSettings[this.dataTemplateCode]

      this.$refs.observer.validate().then(validResult => {
        if (validResult) {
          // 对象转数组
          const currentData = this.unmaskData(formSettings.elements, this.current)
          const originalData = this.unmaskData(formSettings.elements, this.original)
          if (this.assists.tool.deepCompare(currentData, originalData)) {
            return this.$emit('saveCallBack', currentData)
          }
          const finalData = this.compareDataObjects(currentData, originalData)

          // 判断update or create
          let url = ''
          if (this.assists.tool.isEmpty(this.saveParameters)) {
            url = ''
          } else if (this.assists.tool.isNotEmpty(this.saveParameters.payload[this.primaryKey])) {
            url = formSettings.actionProperty.update.url
          } else {
            url = formSettings.actionProperty.create.url
          }

          this.$emit('loadingCallBack', true)
          ajax.post(
            url,
            {
              [this.saveParameters.externalKey]: finalData,
              ...this.saveParameters.payload
            },
            res => {
              this.$emit('saveCallBack', currentData)
              this.$emit('loadingCallBack', false)
            }, (err) => {
              this.$emit('loadingCallBack', false)
              this.$store.commit('setPopupInformation', {
                message: err.message
              })
            }
          )
        }
      })
    },
    compareDataObjects (current, original) {
      // 对比修改前后的数组，只把已修改的数据传给后端
      const finalObj = {}

      for (const key in current) {
        if (!Object.prototype.hasOwnProperty.call(original, key) || current[key] !== original[key]) {
          finalObj[key] = current[key]
        }
      }

      return finalObj
    },
    unmaskData (elements, val) {
      // const data = { ...val }
      const data = JSON.parse(JSON.stringify(val))

      for (const element of elements) {
        const newData = data[element.elementKey]
        if (this.assists.tool.isEmpty(newData)) {
          continue
        }

        const foregroundProperty = element.foregroundProperty
        if (foregroundProperty && foregroundProperty.mask) {
          data[element.elementKey] = newData.replace(/[^0-9]/gi, '')
        }
      }

      return data
    },
    formatQuestion (question) {
      return _.template(question)({
        data: {
          fullName: this.assists.tool.getFullName(
            this.$store.getters.getPersonalSection
          )
        }
      })
    },
    transferStringValue (property) {
      for (const key of Object.keys(property)) {
        if (property[key] === 'true') {
          property[key] = true
        } else if (property[key] === 'false') {
          property[key] = false
        }
      }
    },
    judgmentDisplayAssociate (associate) {
      return associate.items.every(items => {
        return this.current[items.key] === items.value
      })
    },
    blurEvent (key) {
      if (key === 'routingNo') {
        this.$emit('handleBankMapping', 'Map Loading')
        const rn = this.current[key]
        if (this.assists.tool.isEmpty(rn) || rn.length !== 9) return
        InfrastructureApi.mapBankInfo(rn, (res) => {
          if (this.assists.tool.isNotEmpty(res.customer_name)) {
            this.current.bankName = res.customer_name
            this.$emit('handleBankMapping', 'Complete')
          } else {
            // 没查到bankName，置空该字段，并变为可编辑
            this.current.bankName = ''
            this.formsSettings[this.dataTemplateCode].elements.find(i => i.elementKey === 'bankName').controlProperty.disabled = false
            this.$emit('handleBankMapping', 'Not Find')
          }
        }, () => {})
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.red-dot
  color: #ff5050
</style>
